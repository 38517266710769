/*
This software is Copyright ©️ 2020 The University of Southern California. All Rights Reserved. 
Permission to use, copy, modify, and distribute this software and its documentation for educational, research and non-profit purposes, without fee, and without a written agreement is hereby granted, provided that the above copyright notice and subject to the full license file found in the root of this software deliverable. Permission to make commercial use of this software may be obtained by contacting:  USC Stevens Center for Innovation University of Southern California 1150 S. Olive Street, Suite 2300, Los Angeles, CA 90115, USA Email: accounting@stevens.usc.edu

The full terms of this copyright and license should always be found in the root directory of this software deliverable as "license.txt" and if these terms are not found with this software, please contact the USC Stevens Center for the full license.
*/
import { v4 as uuid } from 'uuid';
import { Avatar, savePlayer } from '.';
import { jsonLlmRequest } from '../../../classes/api-helpers';
import { AzureServiceModel, OpenAiServiceModel } from '../../../classes/types';
import {
  GenericLlmRequest,
  PromptOutputTypes,
  PromptRoles,
} from '../../../types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Schema } from 'jsonschema';
import { arrayGetRandom, randomInt } from '../../../helpers';

export const pickAvatarSchema: Schema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      type: { type: 'string' },
      id: { type: 'string' },
      description: { type: 'string' },
    },
  },
  required: ['id', 'type', 'description'],
};

export interface Avatars {
  avatar: Avatar[];
}

export function useWithPlayer() {
  const dispatch = useAppDispatch();
  const { player, loadStatus, saveStatus } = useAppSelector(
    (state) => state.playerData
  );

  function createPlayerName(name: string): void {
    dispatch(
      savePlayer({
        clientId: uuid(),
        name: name,
        avatar: [],
        description: '',
      })
    );
  }

  function saveAvatar(description: string, avatar: Avatar[]): void {
    if (!player) return;
    dispatch(
      savePlayer({
        clientId: player.clientId,
        name: player.name,
        avatar: avatar,
        description: description,
      })
    );
  }

  async function loadAvatarsFromDesc(
    desc: string
  ): Promise<{ message: string; avatars: Avatars[] }> {
    try {
      const items = [
        ...SPRITE_BODY,
        ...SPRITE_HAIR,
        ...SPRITE_CLOTHES,
        ...SPRITE_ACCESSORY,
      ];
      const items_ids = items.map((i) => i.id);
      let sprites = await requestAvatarItems(desc, items, 20);
      sprites = sprites.filter((s) => items_ids.includes(s.id));
      const message =
        sprites.length >= 5
          ? 'Select an avatar or try describing your avatar again:'
          : 'Too few results. Describe avatar again or use a random one:';
      const avatars: Avatars[] = [];
      for (let i = 0; i < 10; i++) {
        const avatar: Avatar[] = [];
        const bodies = sprites.filter((s) => s.type.endsWith('body'));
        const body = {
          ...(bodies[i % bodies.length] || arrayGetRandom(SPRITE_BODY)),
        };
        body.variant = body.variants
          ? randomInt(body.variants.length)
          : undefined;
        avatar.push(body);

        const tops = sprites.filter(
          (s) =>
            s.type.endsWith('clothes_top') || s.type.endsWith('clothes_outfit')
        );
        const top = {
          ...(tops[i % tops.length] ||
            arrayGetRandom(
              SPRITE_CLOTHES.filter(
                (s) =>
                  s.type.endsWith('clothes_top') ||
                  s.type.endsWith('clothes_outfit')
              )
            )),
        };
        top.variant = top.variants ? randomInt(top.variants.length) : undefined;
        avatar.push(top);

        if (top.type.endsWith('_top')) {
          const bottoms = sprites.filter((s) =>
            s.type.endsWith('clothes_bottom')
          );
          const bottom = {
            ...(bottoms[i % bottoms.length] ||
              arrayGetRandom(
                SPRITE_CLOTHES.filter((s) => s.type.endsWith('clothes_bottom'))
              )),
          };
          bottom.variant = bottom.variants
            ? randomInt(bottom.variants.length)
            : undefined;
          avatar.push(bottom);
        }

        const hairStyles = sprites.filter((s) => s.type.endsWith('hair'));
        const hair = {
          ...(hairStyles[i % hairStyles.length] || arrayGetRandom(SPRITE_HAIR)),
        };
        hair.variant = hair.variants
          ? randomInt(hair.variants.length)
          : undefined;
        avatar.push(hair);

        const accessories = sprites.filter((s) => s.type.endsWith('acc'));
        const acc = { ...accessories[i % accessories.length] };
        if (acc) {
          acc.variant = acc.variants
            ? randomInt(acc.variants.length)
            : undefined;
          avatar.push(acc);
        }
        avatars.push({ avatar });
      }
      return {
        message,
        avatars,
      };
    } catch (err) {
      console.error(err);
      return {
        message: 'Something went wrong. Describe your avatar again:',
        avatars: [],
      };
    }
  }

  async function requestAvatarItems(
    desc: string,
    items: Avatar[],
    n: number
  ): Promise<Avatar[]> {
    try {
      const request: GenericLlmRequest = {
        prompts: [
          {
            promptText: JSON.stringify(items),
            promptRole: PromptRoles.USER,
          },
          {
            promptText: `Based on the following description, choose ${n} items to add from the list of items above.`,
            promptRole: PromptRoles.USER,
          },
          {
            promptText: desc,
            promptRole: PromptRoles.USER,
          },
        ],
        targetAiServiceModel: AzureServiceModel,
        outputDataType: PromptOutputTypes.JSON,
        responseFormat: `
            Please only respond in JSON.
            Validate that your response is in valid JSON.
            Respond in this format:
              [{
                  "type": "string"        // the type of the item
                  "id": "string"          // the id of the item
                  "description": "string" // the description of the item
              }]
          `,
      };
      const res = await jsonLlmRequest<Avatar[]>(request, pickAvatarSchema);
      return res;
    } catch (err) {
      return [];
    }
  }

  return {
    player,
    loadStatus,
    saveStatus,
    createPlayerName,
    saveAvatar,
    loadAvatarsFromDesc,
  };
}

/** */

export const CHAT_AVATAR_HEADS: Avatar[] = [
  {
    type: 'head',
    id: 'boy_1',
    description: [
      'Boy',
      'Male',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Short hair',
      'Bob cut',
      'Bowl cut',
      'Bangs',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_2',
    description: [
      'Boy',
      'Male',
      'White',
      'Caucasian',
      'East Asian',
      'Asian',
      'White skin',
      'Brown hair',
      'Short hair',
      'Side part',
      'Side bangs',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_3',
    description: [
      'Boy',
      'Male',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Short hair',
      'Bangs',
      'Mop top',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_4',
    description: [
      'Boy',
      'Male',
      'White',
      'Caucasian',
      'East Asian',
      'Asian',
      'White skin',
      'Brown hair',
      'Short hair',
      'Curly hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_5',
    description: [
      'Boy',
      'Male',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Short hair',
      'Side part',
      'Side bangs',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_6',
    description: [
      'Boy',
      'Male',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Short hair',
      'Crew cut',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_7',
    description: [
      'Boy',
      'Male',
      'East Asian',
      'Asian',
      'White skin',
      'Blue hair',
      'Dyed hair',
      'Kpop',
      'Short hair',
      'Styled hair',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_8',
    description: [
      'Boy',
      'Male',
      'White',
      'Caucasian',
      'Hispanic',
      'White skin',
      'Light brown hair',
      'Medium hair',
      'Forehead',
      'Neck-length hair',
      'Mullet',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_9',
    description: [
      'Boy',
      'Male',
      'White',
      'Caucasian',
      'White skin',
      'Light brown hair',
      'Dirty blonde hair',
      'Short hair',
      'Curly hair',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_10',
    description: [
      'Boy',
      'Male',
      'White',
      'Caucasian',
      'White skin',
      'Light blonde hair',
      'Platinum blonde hair',
      'Short hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_11',
    description: [
      'Boy',
      'Male',
      'White',
      'Caucasian',
      'White skin',
      'Brown hair',
      'Short hair',
      'Slicked back hair',
      'Grease',
      'Forehead',
      'Hair gel',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_12',
    description: [
      'Boy',
      'Male',
      'Black',
      'African American',
      'POC',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Short hair',
      'Shaved',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_13',
    description: [
      'Boy',
      'Male',
      'Black',
      'African American',
      'POC',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Curls',
      'Afro',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_14',
    description: [
      'Boy',
      'Male',
      'Black',
      'African American',
      'POC',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Cornrows',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_15',
    description: [
      'Boy',
      'Male',
      'Black',
      'African American',
      'POC',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Afro',
      'Knots',
      'Locs',
      'Dreadlocks',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_16',
    description: [
      'Boy',
      'Male',
      'Black',
      'African American',
      'POC',
      'South asian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Curls',
      'Short hair',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_17',
    description: [
      'Boy',
      'Male',
      'Black',
      'African American',
      'POC',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Dreadlocks',
      'Locs',
      'Long hair',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_18',
    description: [
      'Boy',
      'Male',
      'Black',
      'South asian',
      'POC',
      'Blasian',
      'Indian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Short hair',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_19',
    description: [
      'Boy',
      'Male',
      'Black',
      'South asian',
      'POC',
      'Blasian',
      'Indian',
      'Black skin',
      'Brown skin',
      'Dark brown hair',
      'Short hair',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'boy_20',
    description: [
      'Boy',
      'Male',
      'Black',
      'South asian',
      'POC',
      'Blasian',
      'Indian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Short hair',
      'Slicked back',
      'Hair gel',
      'Grease',
      'Forehead',
    ].join(', '),
  },

  {
    type: 'head',
    id: 'girl_1',
    description: [
      'Girl',
      'Female',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Long hair',
      'Straight hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_2',
    description: [
      'Girl',
      'Female',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Short hair',
      'Bob cut',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_3',
    description: [
      'Girl',
      'Female',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Short hair',
      'Straight hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_4',
    description: [
      'Girl',
      'Female',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Long hair',
      'Medium hair',
      'Straight hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_5',
    description: [
      'Girl',
      'Female',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Short hair',
      'Straight hair',
      'Bangs',
      'Bowl cut',
      'Bob cut',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_6',
    description: [
      'Girl',
      'Female',
      'East Asian',
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Black hair',
      'Short hair',
      'Straight hair',
      'Forehead',
      'Pixie cut',
      'Butch',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_7',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'South Asian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Ponytail',
      'High ponytail',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_8',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'South Asian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Medium hair',
      'Locs',
      'Dreadlocks',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_9',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'South Asian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Short hair',
      'Dark brown hair',
      'Locs',
      'Dreadlocks',
      'Forehead',
      'Ponytail',
      'High ponytail',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_10',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'South Asian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Medium hair',
      'Medium hair',
      'Cornrows',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_11',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Short hair',
      'Afro',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_12',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'South asian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Medium hair',
      'Afro',
      'Cornrows',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_13',
    description: [
      'Girl',
      'Female',
      'White',
      'Caucasian',
      'White skin',
      'Light brown hair',
      'Blonde hair',
      'Medium hair',
      'Ponytail',
      'Bun',
      'Highlights',
      'Dyed hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_14',
    description: [
      'Girl',
      'Female',
      'White',
      'Caucasian',
      'White skin',
      'Brown hair',
      'Long hair',
      'Straight hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_15',
    description: [
      'Girl',
      'Female',
      'White',
      'Caucasian',
      'White skin',
      'Brown hair',
      'Dark brown hair',
      'Short hair',
      'Buns',
      'Bun',
      'Twin tails',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_16',
    description: [
      'Girl',
      'Female',
      'White',
      'Caucasian',
      'White skin',
      'Brown hair',
      'Dark brown hair',
      'Medium hair',
      'Curly hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_17',
    description: [
      'Girl',
      'Female',
      'White',
      'Caucasian',
      'White skin',
      'Brown hair',
      'Dark brown hair',
      'Short hair',
      'Curly hair',
      'Bob cut',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_18',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'South asian',
      'Indian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Medium hair',
      'Straight hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_19',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'South asian',
      'Indian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Short hair',
      'Curly hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_20',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'South asian',
      'Indian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Long hair',
      'Straight hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_21',
    description: [
      'Girl',
      'Female',
      'Black',
      'African American',
      'POC',
      'South asian',
      'Indian',
      'Black skin',
      'Brown skin',
      'Black hair',
      'Dark brown hair',
      'Short hair',
      'Pixie cut',
      'Cornrows',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_22',
    description: [
      'Girl',
      'Female',
      'White skin',
      'Pink hair',
      'Strawberry blonde hair',
      'Medium hair',
      'Bobcut',
      'Dyed hair',
      'Forehead',
      'Cat ears',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_23',
    description: [
      'Girl',
      'Female',
      'White skin',
      'Blue hair',
      'Purple hair',
      'Medium hair',
      'Ponytails',
      'Pigtails',
      'Twin tails',
      'Dyed hair',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_24',
    description: [
      'Girl',
      'Female',
      'White',
      'Caucasian',
      'Asian',
      'East asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Blue hair',
      'Purple hair',
      'Long hair',
      'Ponytails',
      'Pigtails',
      'Twin tails',
      'Forehead',
      'Braids',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_25',
    description: [
      'Girl',
      'Female',
      'Asian',
      'East asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Blue hair',
      'Purple hair',
      'Medium hair',
      'Ponytails',
      'Pigtails',
      'Twin tails',
      'Bangs',
      'Buns',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_26',
    description: [
      'Girl',
      'Female',
      'Asian',
      'East asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Pink hair',
      'Purple hair',
      'Dyed hair',
      'Long hair',
      'Ponytail',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_27',
    description: [
      'Girl',
      'Female',
      'White',
      'Caucasian',
      'White skin',
      'Pink hair',
      'Blonde hair',
      'Purple hair',
      'Strawberry blonde hair',
      'Dyed hair',
      'Long hair',
      'Ponytails',
      'Pigtails',
      'Twin tails',
      'Forehead',
    ].join(', '),
  },
  {
    type: 'head',
    id: 'girl_28',
    description: [
      'Girl',
      'Female',
      'White',
      'Caucasian',
      'White skin',
      'Pink hair',
      'Strawberry blonde hair',
      'Dyed hair',
      'Short hair',
      'Forehead',
      'Pixie cut',
      'Butch',
    ].join(', '),
  },
];

export const CHAT_AVATAR_BROWS: Avatar[] = [
  {
    type: 'eyebrows',
    id: 'brows_1',
    description: [
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'Black hair',
      'Dark hair',
    ].join(', '),
  },
  {
    type: 'eyebrows',
    id: 'brows_2',
    description: [
      'Caucasian',
      'White',
      'European',
      'Light brown hair',
      'Blonde hair',
      'Light hair',
    ].join(', '),
  },
  {
    type: 'eyebrows',
    id: 'brows_3',
    description: [
      'Dark brown hair',
      'Brown hair',
      'Red hair',
      'Purple hair',
    ].join(', '),
  },
];

export const CHAT_AVATAR_EYES: Avatar[] = [
  {
    type: 'eyes',
    id: 'eyes_1',
    description: [
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Light skin',
      'Black eyes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_2',
    description: [
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Light skin',
      'Black eyes',
      'Gray eyes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_3',
    description: [
      'Asian',
      'Chinese',
      'Japanese',
      'Korean',
      'White skin',
      'Light skin',
      'Brown eyes',
      'Dark brown eyes',
      'Red eyes',
      'Girl',
      'Female',
      'Lashes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_4',
    description: [
      'Caucasian',
      'European',
      'White',
      'White skin',
      'Light skin',
      'Light brown skin',
      'Tan skin',
      'Brown eyes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_5',
    description: [
      'Caucasian',
      'European',
      'White',
      'White skin',
      'Light skin',
      'Light brown skin',
      'Tan skin',
      'Green eyes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_6',
    description: [
      'Caucasian',
      'European',
      'White',
      'White skin',
      'Light skin',
      'Light brown skin',
      'Tan skin',
      'Blue eyes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_7',
    description: [
      'Black',
      'African American',
      'POC',
      'Black skin',
      'Brown skin',
      'Dark skin',
      'Brown eyes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_8',
    description: [
      'Black',
      'African American',
      'POC',
      'Black skin',
      'Brown skin',
      'Dark skin',
      'Light brown eyes',
      'Green eyes',
      'Amber eyes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_9',
    description: [
      'Black',
      'African American',
      'POC',
      'Black skin',
      'Brown skin',
      'Dark skin',
      'Black eyes',
      'Dark brown eyes',
      'Girl',
      'Female',
      'Lashes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_10',
    description: [
      'Caucasian',
      'European',
      'White',
      'White skin',
      'Light skin',
      'Blue eyes',
      'Girl',
      'Female',
      'Lashes',
    ].join(', '),
  },
  {
    type: 'eyes',
    id: 'eyes_11',
    description: [
      'Caucasian',
      'European',
      'White',
      'White skin',
      'Light skin',
      'Green eyes',
      'Amber eyes',
      'Light brown eyes',
      'Girl',
      'Female',
      'Lashes',
    ].join(', '),
  },
];

export const CHAT_AVATAR_NOSE: Avatar[] = [
  {
    type: 'nose',
    id: 'nose_1',
    description: ['White', 'White skin', 'Light skin'].join(', '),
  },
  {
    type: 'nose',
    id: 'nose_2',
    description: [
      'African American',
      'POC',
      'Black',
      'Black skin',
      'Brown skin',
      'Dark skin',
    ].join(', '),
  },
];

export const CHAT_AVATAR_MOUTH: Avatar[] = [
  {
    type: 'mouth',
    id: 'mouth1',
    description: ['White', 'White skin', 'Light skin'].join(', '),
  },
  {
    type: 'mouth',
    id: 'mouth2',
    description: [
      'African American',
      'POC',
      'Black',
      'Black skin',
      'Brown skin',
      'Dark skin',
    ].join(', '),
  },
];

/** */

export const SPRITE_BODY: Avatar[] = [
  {
    type: 'sprite_body',
    id: 'char1',
    description: [
      'Caucasian, European, East asian, Chinese, Japanese, Korean',
      'Pale skin, Light skin, White skin',
    ].join(', '),
  },
  {
    type: 'sprite_body',
    id: 'char2',
    description: [
      'Caucasian, European, East asian, Chinese, Japanese, Korean',
      'Pale skin, Light skin, White skin',
    ].join(', '),
  },
  {
    type: 'sprite_body',
    id: 'char3',
    description: [
      'Caucasian, European, East asian, Chinese, Japanese, Korean',
      'South asian, Hispanic, Latino, Indian, Native American, Filipino, Pacific islander',
      'Slightly tanned skin, Tanned skin, Tan skin, Suntan',
    ].join(', '),
  },
  {
    type: 'sprite_body',
    id: 'char4',
    description: [
      'Caucasian, European, East asian, Chinese, Japanese, Korean',
      'South asian, Hispanic, Latino, Indian, Native American, Filipino, Pacific islander',
      'Slightly tanned skin, Tanned skin, Tan skin, Suntan',
    ].join(', '),
  },
  {
    type: 'sprite_body',
    id: 'char5',
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'South asian, Hispanic, Latino, Indian, Native American, Filipino, Pacific islander',
      'Brown skin, Light brown skin, Darker skin, POC',
    ].join(', '),
  },
  {
    type: 'sprite_body',
    id: 'char6',
    description: [
      'South asian, Hispanic, Latino, Indian, Native American, Filipino, Pacific islander',
      'Brown skin, Light brown skin, Darker skin, POC',
    ].join(', '),
  },
  {
    type: 'sprite_body',
    id: 'char7',
    description: [
      'African, African American',
      'Black skin, Brown skin, Dark brown skin, Darker skin, POC',
    ].join(', '),
  },
  {
    type: 'sprite_body',
    id: 'char8',
    description: [
      'African, African American',
      'Black skin, Brown skin, Dark brown skin, Darker skin, POC',
    ].join(', '),
  },
];

export const SPRITE_HAIR: Avatar[] = [
  {
    type: 'sprite_hair',
    id: 'bob',
    variants: [
      'black',
      'blonde',
      'brown',
      'brownlight',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Bob, Short hair, Side part',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'braids',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Braids, Ponytails, Twin tails, Medium hair, Bangs',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'buzzcut',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Boy, Male, Man, Guy, Dude',
      'Buzzcut, Shaved head, Crew cut, Short hair, Army',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'curly',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Boy, Male, Man, Guy, Dude',
      'Curly hair, Curls, Afro, Short hair',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'emo',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Boy, Male, Man, Guy, Dude',
      'Emo, Asymmetrical haircut, Bangs, Side part, Short hair',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'extra_long_skirt',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Long hair, Extra long hair, Straight hair, Skirt hair, Bangs, Side part',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'extra_long',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Long hair, Extra long hair, Straight hair, Bangs, Side part',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'french_curl',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'French curl, Curly hair, Curls, Short hair, Bangs, Bob',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'gentleman',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Boy, Male, Man, Guy, Dude',
      'Gentleman, Fancy, Tuxedo, Short hair',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'long_straight',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Long hair, Straight hair, Bangs, Side part',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'long_straight_skirt',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Long hair, Straight hair, Skirt hair, Bangs, Side part',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'midiwave',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Medium hair, Wavy hair, Curly hair, Bangs, Side part',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'ponytail',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Medium hair, Ponytail, Bangs, Sporty',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'spacebuns',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Short hair, Buns, Space buns, Bangs',
    ].join(', '),
  },
  {
    type: 'sprite_hair',
    id: 'wavy',
    variants: [
      'black',
      'blonde',
      'brown',
      'brown_light',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: [
      'Girl, Female, Woman, Chick, Lady',
      'Medium hair, Wavy hair, Bangs',
    ].join(', '),
  },
];

export const SPRITE_CLOTHES: Avatar[] = [
  {
    type: 'sprite_clothes_top',
    id: 'basic',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'No pattern, Plain, Simple',
      'T-shirt, Shirt, Short-sleeved shirt, Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_outfit',
    id: 'clown',
    variants: ['black', 'red'],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Clown, Joker, Jester, Black pants, Black shirt, Yellow buttons',
      'Halloween, Spooky, Scary, Funny',
      'Outfit, Cosplay, Costume',
      'Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_outfit',
    id: 'dress',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Girl, Female, Woman, Chick, Lady',
      'No pattern, Plain, Simple, Pretty, Girly',
      'Dress, Gown, Outfit',
      'Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_top',
    id: 'floral',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Pattern, Flower, Floral, Print shirt, Plants, Nature',
      'T-shirt, Shirt, Short-sleeved shirt, Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_top',
    id: 'overalls',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Overalls, Jumper, Work, Coverall',
      'T-shirt, Shirt, Short-sleeved shirt, Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_bottom',
    id: 'pants',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'No pattern, Plain, Simple',
      'Pants, Suit, Bottom, Long pants, Shorts, Formal, Tuxedo',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_outfit',
    id: 'pumpkin',
    variants: ['purple', 'black'],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Pumpkin, Jack-o-lantern, Purple',
      'Halloween, Spooky, Scary, Funny',
      'Outfit, Cosplay, Costume',
      'Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_top',
    id: 'sailor_bow',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Girl, Female, Woman, Chick, Lady',
      'Sailor suit, Sailor, Cute, Uniform, School uniform, Tie, Navy, Ocean',
      'T-shirt, Shirt, Short-sleeved shirt, Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_top',
    id: 'sailor',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Sailor suit, Sailor, Cute, Uniform, School uniform, Tie, Navy, Ocean',
      'T-shirt, Shirt, Short-sleeved shirt, Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_bottom',
    id: 'skirt',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Girl, Female, Woman, Chick, Lady',
      'No pattern, Plain, Simple',
      'Skirt, Bottom, Cute, Girly',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_top',
    id: 'skull',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Pattern, Print shirt, Skull, Zombie, Skeleton, Spooky, Scary, Halloween, Emo, Goth',
      'T-shirt, Shirt, Short-sleeved shirt, Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_top',
    id: 'spaghetti',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Summer, Casual, Simple',
      'Tank top, Short-sleeved shirt, Sleeveless shirt, Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_outfit',
    id: 'spooky',
    variants: ['black'],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Ghost, Zombie, Black pants, Black shirt, White buttons',
      'Halloween, Spooky, Scary, Funny',
      'Outfit, Cosplay, Costume',
      'Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_top',
    id: 'sporty',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Pattern, Print shirt, Sporty, Jersey, Sports, Jock, Casual',
      'T-shirt, Shirt, Short-sleeved shirt, Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_top',
    id: 'stripe',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Pattern, Print shirt, Stripe, Striped shirt, Casual',
      'T-shirt, Shirt, Short-sleeved shirt, Top',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_top',
    id: 'suit',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Shirt, Suit, Tie, Top, Formal, Tuxedo',
    ].join(', '),
  },
  {
    type: 'sprite_clothes_outfit',
    id: 'witch',
    variants: ['purple'],
    description: [
      'Human, Person',
      'Boy, Male, Man, Guy, Dude',
      'Girl, Female, Woman, Chick, Lady',
      'Witch, Mage, Wizard, Purple, Dress, Robe',
      'Halloween, Spooky, Scary, Funny',
      'Outfit, Cosplay, Costume',
      'Top',
    ].join(', '),
  },
];

export const SPRITE_ACCESSORY: Avatar[] = [
  {
    type: 'sprite_acc',
    id: 'earring_emerald_silver',
    description: ['Earring', 'Earrings', 'Pretty', 'Girly'].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'earring_emerald',
    description: ['Earring', 'Earrings', 'Pretty', 'Girly'].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'earring_red_silver',
    description: ['Earring', 'Earrings', 'Pretty', 'Girly'].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'earring_red',
    description: ['Earring', 'Earrings', 'Pretty', 'Girly'].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'hat_cowboy',
    description: ['Cowboy', 'Hat', 'Southern', 'Western'].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'hat_lucky',
    description: ['Leprechaun', 'Hat', 'Irish', 'Clover', 'Lucky'].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'hat_pumpkin',
    description: ['Pumpkin', 'Hat', 'Halloween', 'Spooky', 'Scary'].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'beard',
    variants: [
      'black',
      'blonde',
      'brown',
      'brownlight',
      'copper',
      'emerald',
      'green',
      'grey',
      'lilac',
      'navy',
      'pink',
      'purple',
      'red',
      'turquoise',
    ],
    description: 'Beard, Facial hair, Boy, Male, Man, Guy, Dude, Adult, Hairy',
  },
  {
    type: 'sprite_acc',
    id: 'glasses_sun',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: ['Sunglasses', 'Shades', 'Cool', 'Beach', 'Summer'].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'glasses',
    variants: [
      'black',
      'blue',
      'bluelight',
      'brown',
      'green',
      'greenlight',
      'pink',
      'purple',
      'red',
      'white',
    ],
    description: ['Glasses', 'Spectacles', 'Smart', 'Nerd'].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'hat_witch',
    description: [
      'Witch',
      'Wizard',
      'Hat',
      'Halloween',
      'Spooky',
      'Scary',
      'Magic',
      'Magical',
    ].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'mask_clown_blue',
    description: [
      'Clown',
      'Joker',
      'Mask',
      'Halloween',
      'Spooky',
      'Scary',
    ].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'mask_clown_red',
    description: [
      'Clown',
      'Joker',
      'Mask',
      'Halloween',
      'Spooky',
      'Scary',
    ].join(', '),
  },
  {
    type: 'sprite_acc',
    id: 'mask_spooky',
    description: [
      'Ghost',
      'Zombie',
      'Skeleton',
      'Mask',
      'Halloween',
      'Spooky',
      'Scary',
    ].join(', '),
  },
];
